// extracted by mini-css-extract-plugin
export var active = "CommonResourcesOverview-module--active--XyL-K";
export var caseStudiesArticleList = "CommonResourcesOverview-module--caseStudiesArticleList--bCYCG";
export var caseStudiesFirstArticle = "CommonResourcesOverview-module--caseStudiesFirstArticle--tY2ge";
export var categoryTabs = "CommonResourcesOverview-module--categoryTabs--oU+V8";
export var first = "CommonResourcesOverview-module--first--bP-IS";
export var fullWidthImage = "CommonResourcesOverview-module--fullWidthImage--dnfEZ";
export var inactive = "CommonResourcesOverview-module--inactive--bwYSp";
export var learnMore = "CommonResourcesOverview-module--learnMore--N5gtd";
export var learnMoreBtn = "CommonResourcesOverview-module--learnMoreBtn--GMBtN";
export var limitWidthOnLargeScreens = "CommonResourcesOverview-module--limitWidthOnLargeScreens--o6uOM";
export var tab = "CommonResourcesOverview-module--tab--1yDpy";
export var top = "CommonResourcesOverview-module--top--41NxG";