import React from "react";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import CentralizedPlatform from "@components/CentralizedPlatform/CentralizedPlatform";

export const StandardizeResearchData = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<SectionTitle
			title="Standardize the way your team records research data - all in one place"
			textColor="text-dark"
			titleWidth={1088}
			marginBottom={24}
			marginBottomTablet={24}
		/>
		<CentralizedPlatform light/>
	</BackgroundFadeContainer>
);
