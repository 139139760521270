import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkCircles0,
	PinkCircles1,
} from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import {
	ArrowLinkButton,
	GetStartedButton,
} from "@components/Button_new/Button";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { pinkText } from "@components/Typography/Typography";
import { StaticImage } from "gatsby-plugin-image";
import TryGenemodForFree from "@components/TryGenemodForFree/Index";
import * as styles from "../../CommonResourcesOverview.module.scss";
import { StandardizeResearchData } from "@components/StandardizeResearchData/StandardizeResearchData";

export default function MolecularBiology() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "molecular-biology-software",
			}}
		>
			<Hero />
			<SequenceDesign />
			<DNAEditor />
			<TryGenemodForFree type="blueFade" />
			<TrustedBy backgroundType="solidLightPink" />
			<StandardizeResearchData />
		</PageLayout>
	);
}

function Hero() {
	return (
		<BackgroundFadeContainer type="blueFade" skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <BlueSquares1 />,
					xOffset: -70,
					yOffset: 165,
				}}
				rightShape={{
					shape: <BlueSquares2 />,
					yOffset: 400,
				}}
			>
				<div
					style={{
						marginBottom: 40,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<SectionTitle
						title="User-friendly design tools for sequence editing"
						subtitle="Track all of your sequence edits through shared libraries to eliminate duplicate efforts."
						textColor="ui-01"
						marginTop={163}
						marginTopTablet={186}
						marginTopMobile={146}
						titleWidth={800}
						subtitleWidth={608}
					/>
					<GetStartedButton />
					<StaticImage
						loading="eager"
						src="../../../assets/products/DNA_editor_large.png"
						alt="DNA converter"
						style={{ marginTop: 24 }}
						className={styles.fullWidthImage}
					/>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function SequenceDesign() {
	return (
		<BackgroundFadeContainer type="solidLightPink" skewTop skewBottom>
			<SectionTitle
				title="Unified sequence design and end-to-end traceability"
				textColor="text-dark"
				titleWidth={650}
				marginBottom={60}
				marginBottomTablet={12}
				marginBottomMobile={12}
			/>
			<ImageAndBulletPoints
				imagePosition="left"
				withImageShadowMargins={false}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/DNA_converter.png"
						alt="DNA converter"
						width={614}
						height={414}
					/>
				}
				bullets={[
					{
						title: "Input Output",
						text: (
							<>
								Easily reverse and/or complement DNA sequences
								according to IUPAC Degeneracies.
								<ArrowLinkButton
									color="marine"
									to={"https://www.bioinformatics.org/"}
									target="_blank"
									internal={false}
									style={{ marginTop: 12 }}
								>
									https://www.bioinformatics.org/
								</ArrowLinkButton>
							</>
						),
					},
					{
						title: "Automatically compute biochemical properties for any sequence",
						text: (
							<>
								From GC content to melting temp, or molecular
								weight, and more.
								<ArrowLinkButton
									color="marine"
									to={"https://pubmed.ncbi.nlm.nih.gov/"}
									target="_blank"
									internal={false}
									style={{ marginTop: 12 }}
								>
									https://pubmed.ncbi.nlm.nih.gov/
								</ArrowLinkButton>
							</>
						),
					},
					{
						title: "Collaborative real-time editing",
						text: "We take teamwork to the next level by letting you and your team work together in real time on primer design.",
					},
				]}
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "marine",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 530,
				}}
			/>
		</BackgroundFadeContainer>
	);
}

function DNAEditor() {
	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{ shape: <></> }}
				rightShape={{
					shape: <PinkCircles1 />,
					xOffset: 100,
					yOffset: 300,
				}}
			>
				<SectionTitle
					title={pinkText`Software for ${"everyday"} molecular biology`}
					textColor="text-dark"
					titleWidth={890}
					marginBottom={60}
					marginBottomTablet={60}
				/>
				<ImageAndBulletPoints
					image={
						<StaticImage
							loading="eager"
							src="../../../assets/products/DNA_editor.png"
							alt="DNA Editor"
							width={900}
							height={591}
						/>
					}
					bullets={[
						{
							title: "Navigate between tools with ease",
							text: "Convert DNA sequences to RNA, 1-Letter, or 3-Letter, or the reverse from 1-Letter to DNA or RNA sequences.",
						},
						{
							title: pinkText`Store your ${"calculations"} in one central area`,
							text: "We take teamwork to the next level by letting you and your team work together in real time on primer design.",
						},
						{
							title: "Accomplish more together",
							text: "Genemod is your remote-friendly team workspace where science and collaboration meet.",
						},
					]}
					bulletStyles={{
						titleVariant: "HEADER4",
						titleColor: "marine",
						textVariant: "SUBHEADERDESC",
						textColor: "text-dark",
						containerWidth: 476,
					}}
					gap={80}
				/>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
